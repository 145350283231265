<template>
	<div class="page">
		<div>
			<div class="banner_one_img">
				<img src="../assets/img/logo.png" alt="">
				<p class='runtian_name'>润田动销</p>
				<p class='info_tips'>绑定手机号，接收出库通知</p>
			</div>
			<div class="form_login">
				<div class="dl">
					<i class="iconfont icon icon-shouji"></i>
					<input class="input_text" v-model="phone" placeholder="手机号" type="number">
				</div>
				<div class="dl">
					<i class="iconfont icon icon-dunpai"></i>
					<input v-model="code" class="input_text" placeholder="请输入验证码" type="number">
					<span :class="['weui_btn',verify_status ? '' : 'forbid']" @click="send_verify" mini
						plain>{{verify_title}}</span>
				</div>
			</div>
			<div class="weui_big_btn">
				<van-button type="primary" block class="btn" @click="submit">绑 定</van-button>
			</div>

		</div>
	</div>

</template>


<script>

	import {
		send_sms,login,get_bill_list
	} from '@/assets/js/api.js';
	import config from '@/assets/js/methods.js';
	import {
		Toast,Button
	} from 'vant';
	export default {
		components: {
			// Button
		},
		data() {
			return {
				verify_title: '获取验证码',
				verify_status: true,
				phone: '',
				code: '',
				ver_time: 0,
				time: null
			}
		},
		created() {
                let key=localStorage.getItem('token', this.$route.query.token);
				if(!this.$route.query.token && !key){
					this.get_info()
				}else  {
                    localStorage.setItem('token', this.$route.query.token);
                    this.get_list()
                }
		},
		methods: {
            get_list(){
                get_bill_list().then(res=>{
                    this.$router.push({path:'/list'})
                    console.log('res',res);
                })
            },
			get_info() {
					let url = window.location.href;
					window.location.href =config.hostUrl+ '/seller/login/auth?url=' + url
			},
            
			send_verify() {
				if (this.ver_time > 0) {
					return false;
				}
				let reg = 11 && /^((13|14|15|17|18|19)[0-9]{1}\d{8})$/;
				if (!reg.test(this.phone)) {
					Toast('手机号不正确');
					return false;
				}
				this.ver_time = 60;
				send_sms({
					phone: this.phone
				}).then(res => {
					if (res) {
						Toast('发送成功');
						this.verify_key = 1;
					}

				})

				if (this.verify_status) {
					this.verify_status = false;
					this.verify(this)
					this.time = setInterval(() => {
						this.verify(this)
					}, 1000);
				}

			},

			submit() {
				if (!this.verify_key) {
					Toast('获取验证码');
					return false;
				}
				login({
					phone: this.phone,
					code:this.code
				}).then(res => {
					if (!res) {
						return false;
					}
					Toast('绑定成功');
					this.$router.push({path:'/list'})
				})

			},
			verify(_this) {

				_this.verify_title = _this.ver_time + '秒后重新获取';

				if (_this.ver_time <= 0) {
					_this.verify_status = true;
					_this.verify_title = '重新获取';
					clearTimeout(_this.time);
					return false;
				}

				_this.ver_time--;


			}

		},
		mounted() {
			document.body.style.backgroundColor = '#fff';
		},
		beforeDestroy() {
			document.body.style.backgroundColor = '#f3f3f3';
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	body {

		.page {
			background: #fff;

			.runtian_name {
				height: 23px;
				line-height: 23px;
				font-size: 16px;
				font-weight: 600;
				text-align: center;
				margin-top: 10px;
			}

			.info_tips {
				height: 23px;
				line-height: 23px;
				font-size: 14px;
				text-align: center;
				margin-top: 8px;
				color: #888888;
			}
		}
	}
	body .weui_big_btn .btn {
		box-shadow:none
	}
</style>
